import React from "react";

function App() {

  const outerDivStyle = {
    width: "100%",
    height: "100vh",
    display: "flex", 
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const innerDivStyle = {
    padding: "20px", 
    border: "1px solid #dbe2ec",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
  }

  return (
    <div style={outerDivStyle}>
      <div style={innerDivStyle}>
        <h1>Page Not Found</h1>
        <p>
          The link you followed may be broken or the page may have been removed.
        </p>
      </div>
    </div>
  );
}

export default App;
